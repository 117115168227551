import { ScrollArea } from '@radix-ui/react-scroll-area'
import { Check } from 'lucide-react'
import { FlexBox } from '~/components/ui/custom_ui/flexBox'
import List, { ListItem, ListItemSuffix } from '~/components/ui/custom_ui/list'
import { Drawer, DrawerContent } from '~/components/ui/drawer'


const countryCodeList = [
    { country_id: 1, country_name: "United Arab Emirates", country_code: "971" },
    { country_id: 2, country_name: "Saudi Arabia", country_code: "966" },
    { country_id: 3, country_name: "Oman", country_code: "968" },
    { country_id: 4, country_name: "Qatar", country_code: "974" },
    { country_id: 6, country_name: "Bahrain", country_code: "973" },
    { country_id: 5, country_name: "Kuwait", country_code: "965" },
    { country_id: 7, country_name: "Gaza", country_code: "970" },
    { country_id: 8, country_name: "Jordan", country_code: "962" }
]

type countryType = typeof countryCodeList[1]

interface CountryCodeSelectProps {
    selectedValue: string | null;
    onSelectCountryCode: (value: countryType) => void;
    onClose:()=>void;
    open: boolean
}

const CountryCodeSelect = (props: CountryCodeSelectProps) => {
    const { onSelectCountryCode, open, selectedValue , onClose } = props

    const onCountryCodeSelect = (country: countryType) => {
        onSelectCountryCode(country)
        onClose()
    }

    const onDrawerDismiss = () => {
        onClose()
    }

    return (
        <Drawer
            open={open}
            dismissible={true}
            onOpenChange={(open: boolean) => (open ? undefined : onDrawerDismiss())}
        >
            <DrawerContent className="max-h-[96vh]">
                <ScrollArea>
                <List>
                    <FlexBox direction={'column'} className="w-full">

                        {
                            countryCodeList.map(
                                (country, index) =>
                                    <ListItem onClick={() => onCountryCodeSelect(country)} key={country.country_id.toString()} className={`border-b-[${index === countryCodeList.length - 1 ? 0 : 1}px] rounded-none py-6`}>
                                        <p>
                                            <span className="font-semibold text-lg">+{country.country_code} &nbsp;&nbsp;</span>
                                            <span>{country.country_name}</span>
                                        </p>
                                            { selectedValue === country.country_code ? <ListItemSuffix>
                                                <Check className="text-secondary" />
                                            </ListItemSuffix> : null}
                                    </ListItem>
                            )
                        }
                    </FlexBox>
                </List>
                </ScrollArea>
            </DrawerContent>
        </Drawer>
    )
}

export default CountryCodeSelect
